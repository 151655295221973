import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BOOL_VALUE, CLIENT_DOMAIN, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
import { connect } from "react-redux";
import RecipesSliderItem from "./RecipesSliderItem";
const FREE_RECIPE_INDEX = 2;
export class RecipesSlider extends Component {
  openRecipeDetail = (
    link,
    title,
    recipeIndex,
    isUserExpired,
    isSubscriptionUser
  ) => {
    try {
      const isAllowed = this.checkAllowedRecipe(
        recipeIndex,
        isUserExpired,
        isSubscriptionUser
      );
      if (isAllowed) {
        const { isOnApp } = this.props;
        console.log({ link, isOnApp });
        const url = `${CLIENT_DOMAIN}${link}/${isOnApp}`;
        const shareUrl = `${CLIENT_DOMAIN}${link}`;
        sendMessage({
          type: WEBVIEW_ACTION.OPEN_RECIPE_DETAIL,
          url: url,
          shareUrl: shareUrl,
          title: title,
        });
      } else {
        const upgradeUrl = `${CLIENT_DOMAIN}Upgrade`;
        sendMessage({
          type: WEBVIEW_ACTION.OPEN_UPGRADE_PAGE,
          upgradeUrl: upgradeUrl,
        });
      }
    } catch (error) {}
  };
  checkAllowedRecipe = (recipeIndex, isUserExpired, isSubscriptionUser) => {
    let isAllowed = false;
    if (recipeIndex <= FREE_RECIPE_INDEX) {
      isAllowed = true;
    } else {
      if (!isUserExpired && isSubscriptionUser) {
        isAllowed = true;
      }
    }
    return isAllowed;
  };
  render() {
    const {
      recipesData,
      isUserExpired,
      isSubscriptionUser,
      isOnApp,
      category,
      imgData,
    } = this.props;

    return (
      <div>
        <div className="title-main" style={{ margin: "10px 16px 12px" }}>
          {category}
        </div>
        <div className="sliderRecipes">
          {recipesData.map(
            (item, index) =>
              item.isShowing && (
                <div
                  className={
                    this.checkAllowedRecipe(
                      index,
                      isUserExpired,
                      isSubscriptionUser
                    )
                      ? "item"
                      : "item lock"
                  }
                >
                  <Link
                    to="#"
                    onClick={() =>
                      this.openRecipeDetail(
                        item.link,
                        item.title,
                        index,
                        isUserExpired,
                        isSubscriptionUser
                      )
                    }
                  >
                    <RecipesSliderItem
                      image={imgData[index]}
                      title={item.title}
                      link={item.link}
                    />
                  </Link>
                </div>
              )
          )}

          {/* <div className="item">
                        <Link>                            
                           <RecipesSliderItem 
                                image = {Image[3]}
                                titlecontent="Asian Breakfast Oats _WIP_"                           
                           />
                        </Link>                      
                    </div> */}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {}
)(RecipesSlider);
