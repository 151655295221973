export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const POST_USER_INFO = "POST_USER_INFO";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_SURVEYS = "GET_SURVEYS";
export const UPDATE_SURVEYS = "UPDATE_SURVEYS";
export const NEXT_CATEGORY = "NEXT_CATEGORY";
export const BACK_CATEGORY = "BACK_CATEGORY";
export const GET_WORKOUT = "GET_WORKOUT";
export const GET_WORKOUT_BY_TYPE = "GET_WORKOUT_BY_TYPE";
export const GET_WEEK_WORKOUT = "GET_WEEK_WORKOUT";
export const GET_WORKOUT_ID = "GET_WORKOUT_ID";
export const GET_PHOTO = "GET_PHOTO";
export const SEND_EMOTION = "SEND_EMOTION";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const UPDATE_PHOTO = "UPDATE_PHOTO";
export const GET_USERNAME = "GET_USERNAME";
export const UPLOAD_PHOTO = "UPLOAD_PHOTO";
export const GET_CURRENT_WORKOUT = "GET_CURRENT_WORKOUT";
export const GET_EXCERCISE_REPS = "GET_EXCERCISE_REPS";
export const NEXT_EXCERCISE = "NEXT_EXCERCISE";
export const COUNT_PROGRESS = "COUNT_PROGRESS";
export const GET_VIDEOS = "GET_VIDEOS";
export const CLEAR_PROGRESS = "CLEAR_PROGRESS";
export const GET_START_TIME = "GET_START_TIME";
export const GET_END_TIME = "GET_END_TIME";
export const CHECK_EXPIRED = "CHECK_EXPIRED";
export const GET_HEART_RATE = "GET_HEART_RATE";
export const SET_HEART_RATE = "SET_HEART_RATE";
export const SET_USER_EXPIRED = "SET_USER_EXPIRED";
export const SHOW_MODAL_PROFILE = "SHOW_MODAL_PROFILE";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_PROFILE_ASSESSMENT = "SET_PROFILE_ASSESSMENT";
export const GET_ALL_ASSESSMENT = "GET_ALL_ASSESSMENT";
export const RELOAD_ASSESSMENT = "RELOAD_ASSESSMENT";
export const CLEAR_RELOAD = "CLEAR_RELOAD";
export const SET_WORKOUT_DONE = "SET_WORKOUT_DONE";
export const GET_ALL_SLIDER = "GET_ALL_SLIDER";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_OS = "SET_OS";
export const GET_ALL_CARDIO = "GET_ALL_CARDIO";
export const GET_CARDIO_LEFT = "GET_CARDIO_LEFT";
export const GET_ALL_MESSAGE = "GET_ALL_MESSAGE";
export const GET_ASSESSMENT_BY_ID = "GET_ASSESSMENT_BY_ID";
export const GET_REPLIED_ASSESSMENT = "GET_REPLIED_ASSESSMENT";
export const GET_ALL_CARDIO_HISTORY = "GET_ALL_CARDIO_HISTORY";
export const SWITCH_STATUS = "SWITCH_STATUS";
export const ON_BACK = "ON_BACK";
export const GET_ALL_WORKOUT_TYPE = "GET_ALL_WORKOUT_TYPE";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";

export const SET_DEMOWORKOUT_URL = "SET_DEMOWORKOUT_URL";
export const GET_LIST_ACHIEVEMENTS_DETAIL = "GET_LIST_ACHIEVEMENTS_DETAIL";
export const GET_ALL_USER_ACHIEVEMENTS = "GET_ALL_USER_ACHIEVEMENTS";
export const TOGGLE_PLAY_AUDIO = "TOGGLE_PLAY_AUDIO,";
export const CHANGE_FULL_BODY_WORKOUT = "CHANGE_FULL_BODY_WORKOUT";
export const CHANGE_CUSTOM_WORKOUT = "CHANGE_CUSTOM_WORKOUT";
