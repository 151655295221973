import React, { Component, Fragment } from "react";
import Slider from "./Slider/Slider";
import DaysSessions from "./DaysSessions";
import Calendar from "./Calendar/Calendar";
import Menu from "./Menu/Menu";
import { MENU, WEBVIEW_ACTION, APP_URL, CLIENT_DOMAIN } from "../config/config";
import LinksYoutube from "./LinksYoutube";
import Drawer from "react-motion-drawer";
import { sendMessage } from "../ultils/ultils";
import { connect } from "react-redux";
import {
  getUser,
  checkExpired,
  setUserExpired,
  updateRatingStatus,
} from "../store/actions/UserInfo";
import {
  getCurrentWorkOut,
  getWeekWorkOut,
  getWorkOut,
  setWorkoutDone,
  setOS,
  getAllMessage,
} from "../store/actions/GetWorkOut";
import {
  getAllCardio,
  getCardioHistory,
  getCardioLeft,
  getAllCardioHistory,
} from "../store/actions/Cardio";
import { getAllEvents } from "../store/actions/Liveevents";
import { setProductList } from "../store/actions/Upgrade";
import { getAllSlider } from "../store/actions/Slider";
import { getPhoto } from "../store/actions/Photo";
import ModalProfile from "./Modal/ModalProfile";
import { Link } from "react-router-dom";
import {
  setUserProfile,
  setProfileAssessment,
  clearReload,
} from "../store/actions/Profile";
import Moment from "moment";
import { getVideo } from "../store/actions/GetVideo";
import { getRepliedAssessment } from "../store/actions/Assessment";
import { getHeartRateWeek } from "../store/actions/Diary";
import StepsChart from "./StepsChart/StepsChart";
import CalendarSchedule from "./Calendar/CalendarSchedule";
import { getCurrentWeek, isNewWeek, isUserPurchase } from "../ultils/ultils";
import { isNumber } from "util";

import moment from "moment";
import {
  getListAchievementDetail,
  getAllUserAchievements,
} from "../store/actions/Achievements";
class Index extends Component {
  state = {
    stepsList: null,
    totalWorkout: null,
    totalCardio: null,
    workoutLeft: null,
    cardioLeft: null,
    daysLeft: null,
    weeksLeft: null,
    heartRateWeek: null,
    modalShow: false,
    leftTime: null,
    isExpired: null,
    userProfile: null,
    os: null,
    sliders: [],
    productList: null,
    assessmentId: null,
    disabled: true,
    currentWeek: null,
    checkIntro: false,
  };

  modalClose = () => this.setState({ modalShow: false });
  componentWillMount() {
    //console.log("Component WILL MOUNT!");
  }
  componentDidMount = async () => {
    await this.props.getUser();
    const { user, sliders, os, liveEvents, achievementListDetail } = this.props;
    if (!user.id) {
      localStorage.removeItem("playIntro");
      sendMessage({ type: WEBVIEW_ACTION.LOG_OUT });
    }
    // console.log({ user });
    // console.log(parseInt(user.isSubscription) > 0);
    if (!user.workoutType) {
      if (!user.username) {
        if (user.email && user.email.includes("trial_user@")) {
          return this.props.history.push("/surveys");
        } else {
          return this.props.history.push("/info");
        }
      }
    } else if (
      user.email &&
      !user.email.includes("trial_user@") &&
      !user.username
    ) {
      return this.props.history.push("/info");
    } else {
      // Play Intro Video
      const playIntro = localStorage.getItem("playIntro");
      if (!playIntro) {
        return this.props.history.push("/intro");
      }
    }
    this.setState({ checkIntro: true });
    sendMessage({ type: WEBVIEW_ACTION.KEEP_AWAKE, status: false });
    if (user.allWorkoutHistory >= 1) {
      //console.log(user.allWorkoutHistory);
      const data = { type: WEBVIEW_ACTION.ON_NOTIFICATION };
      //console.log({ data });
      sendMessage(data);
    }
    if (user.allWorkoutHistory >= 3 && !user.is_rated) {
      const data = { type: WEBVIEW_ACTION.ON_RATING };
      const isSuccess = await this.props.updateRatingStatus();
      if (isSuccess) {
        //console.log("Updated");
        sendMessage(data);
      }
    } else {
      //console.log("Nahhhhhhh");
    }
    // Set PlayAudio
    const toggleAudio = localStorage.getItem("playAudio");
    if (!toggleAudio) {
      localStorage.setItem("playAudio", true);
    }
    // const data = { type: WEBVIEW_ACTION.ON_NOTIFICATION };
    // console.log({ data });
    // sendMessage(data);
    if (sliders.length === 0) {
      await this.props.getAllSlider();
    }
    window.onpopstate = this.onBackHandler;
    // if (!os) {
    //   this.getDeviceOS()
    // }
    const now = Moment().utc();
    const userExpired = Moment(user.expired_time).utc();
    const diffInDays = userExpired.diff(now, "days");
    const diffInWeeks = userExpired.diff(now, "weeks");
    const weeksLeft = diffInWeeks > 0 ? diffInWeeks : 0;
    const daysLeft =
      diffInDays - weeksLeft * 7 > 0 ? diffInDays - weeksLeft * 7 : 0;
    const isExpired =
      user.expired_time && moment(user.expired_time).diff(moment(), "days") < 0;
    //console.log({ isExpired });
    this.setState({
      weeksLeft: weeksLeft,
      daysLeft: daysLeft,
      leftTime: diffInDays,
      isExpired: isExpired,
    });
    // const expired_time = "2019-10-30 00:00:00";
    // let workoutstartdate = "2019-09-30 00:00:00";
    // const time_now = "2019-12-15 00:00:00";
    // let oldWeek = moment(expired_time).diff(workoutstartdate, 'weeks')
    // const days = moment(time_now).diff(expired_time, 'days');
    // workoutstartdate = moment(workoutstartdate).add(days, 'days').format("YYYY-MM-DD 00:00:00")
    // let newWeek = moment(time_now).diff(workoutstartdate, 'weeks')
    // console.log(oldWeek === newWeek)
    //const userRegDate = Moment(user.reg_date).utc();
    // if (isExpired || !isPurchase) {
    //   await this.props.getCurrentWorkOut(1)
    // } else {
    //   await this.props.getCurrentWorkOut(currentWeek)
    // }
    const workoutStartDate = Moment(user.workout_start_date).utc();
    const isPurchase = isUserPurchase(user.total_days_purchase);
    const currentWeek = getCurrentWeek(workoutStartDate);
    //console.log('currentWeek', currentWeek)
    this.props.checkExpired(isExpired);
    this.props.setUserExpired(userExpired);
    this.props.getAllEvents();
    this.props.getWorkOut();
    this.props.getAllCardio();
    this.props.getCurrentWorkOut();
    if (!achievementListDetail) {
      this.props.getListAchievementDetail();
    }
    await this.props.getAllUserAchievements();
  };
  onBackHandler = () => {
    const { modalShow } = this.state;
    if (modalShow) {
      this.modalClose();
    } else {
      return false;
    }
  };
  _onMessage = (data) => {
    this.setState({
      stepsList: data.stepsList,
      heartRateWeek: data.heartRateWeek,
      os: data.os,
      productList: data.productList,
    });
    this.props.getHeartRateWeek(this.state.heartRateWeek);
    this.props.setProductList(this.state.productList);
    //this.props.setOS(this.state.os);
  };
  _getDeviceOS = async (data) => {
    if (data) {
      await this.props.setOS(data);
      if (data.goUpgrade) {
        this.props.history.push("/Upgrade");
      }
    }
  };

  sendDate = (startDate, endDate) => {
    const data = {
      type: WEBVIEW_ACTION.GET_DATA,
      startDate: startDate,
      endDate: endDate,
    };
    sendMessage(data);
  };
  getDeviceOS = () => {
    const data = { type: WEBVIEW_ACTION.GET_DEVICE_OS };
    sendMessage(data);
  };
  getHeartRate = () => {};
  getKeys = (images) => {
    let newList =
      images.length > 0
        ? images.reduce((r, a) => {
            r[a.date] = r[a.date] || [];
            r[a.date].push(a);
            return r;
          }, {})
        : null;
    let keys = [];
    if (newList) {
      keys = Object.keys(newList);
    }
    return keys;
  };

  componentWillReceiveProps(newProps) {
    //console.log("Component WILL RECIEVE PROPS!");
  }
  shouldComponentUpdate(newProps, newState) {
    return true;
  }
  componentWillUpdate(nextProps, nextState) {
    //console.log("Component WILL UPDATE!");
  }
  componentDidUpdate = async (prevProps, prevState) => {
    window.WebViewBridge = {
      get_data: this._onMessage,
      get_device_os: this._getDeviceOS,
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    const endDate = Moment().utc();
    const startDate = Moment(endDate).subtract(90, "days");
    if (this.props.user !== prevProps.user) {
    }
    if (this.props.currentWorkOut !== prevProps.currentWorkOut) {
      const { user } = this.props;
      //console.log('currentWorkout', this.props.currentWorkOut)
      this.props.setUserProfile(user);
      const { workOutList, cardioList } = this.props;
      let newCardioList = cardioList.reduce((r, a) => {
        r[a.week] = { session: a.session, id: a.id, duration: a.duration };
        return r;
      }, {});
      const { week, workoutHistory } = this.props.currentWorkOut;

      //console.log({ newCardioList, week })

      //console.log('week', week)
      //console.log('cardio', newCardioList[1])
      if (newCardioList[week]) {
        const { cardioHistory } = this.props.currentWorkOut;
        const { cardioLeft, totalCardio } = cardioHistory;
        const cardioId = newCardioList[week].id;
        const duration = newCardioList[week].duration;
        //totalCardio = newCardioList[week].session;
        // const cardioHistory = await this.props.getCardioHistory(cardioId);
        //console.log('cardioId', cardioId)
        //console.log('cardioHistory', cardioHistory)

        //console.log('cardioLeft', cardioLeft)
        this.setState({ cardioLeft, totalCardio });
        const data = { cardioLeft, totalCardio, cardioId, duration };
        //console.log('data',data)
        await this.props.getCardioLeft(data);
      }
      await this.props.getAllMessage();
      await this.props.getRepliedAssessment();
      const { totalWorkout, workoutLeft } = workoutHistory;
      //await this.props.getCurrentWorkOut();
      //await this.props.getAllCardioHistory();
      // const totalWorkout = workOutList[week].length;
      // const workoutLeft = workOutList[week].reduce((r, a) => {
      //   if (!a.historyId) {
      //     r.push(a.historyId);
      //   }
      //   return r;
      // }, []).length;
      //console.log('workoutLeft', workoutLeft)
      //console.log('totalWorkout', totalWorkout)
      this.setState({
        totalWorkout: totalWorkout,
        workoutLeft: workoutLeft,
        currentWeek: parseInt(week),
      });
      this.getDeviceOS();
      this.sendDate(startDate, endDate);
    }
    if (this.props.reloadAssessment) {
      await this.props.getUser();
      const { user } = this.props;
      await this.props.setUserProfile(user);
      await this.props.clearReload();
    }
  };
  componentWillUnmount() {
    //console.log("Component WILL UNMOUNT!");
  }
  state = {
    isActive: MENU.INDEX,
  };
  onClick = async () => {
    const { workoutLeft, isExpired } = this.state;
    const { id, week, workoutHistory, latestWeek } = this.props.currentWorkOut;
    const { workOutList, user } = this.props;
    const { total_days_purchase, expired_time } = user;
    // if (isExpired || !total_days_purchase) {
    //   const defaultWeek = '0';
    //   await this.props.getWeekWorkOut(defaultWeek);
    //   this.setState({ disabled: false })
    //   this.props.history.push("/Workout");
    // } else {
    //   if (id && workOutList) {
    //     //console.log(workoutHistory)
    //     const workoutWeek = workOutList[week]
    //     Object.keys(workoutWeek).map(key => {
    //       workoutWeek[key].index = parseInt(key) + 1;
    //     })
    //     const workoutNow = workoutWeek.reduce((r, a) => {
    //       r[a.id] = { name: a.name, description: a.description, duration: a.duration, index: a.index } || [];
    //       return r;
    //     }, {});
    //     //console.log(workoutNow)
    //     const name = workoutNow[id].name;
    //     const description = workoutNow[id].description;
    //     const duration = workoutNow[id].duration;
    //     const index = workoutNow[id].index
    //     this.props.getWeekWorkOut(week);
    //     this.props.history.push(`/ListWorkOut/${id}/${description}/${name}/${duration}/${index}`);
    //   }
    // }
    if (id && workOutList) {
      //console.log(workoutHistory)
      // const workoutWeek = workOutList[week];
      // Object.keys(workoutWeek).map(key => {
      //   workoutWeek[key].index = parseInt(key) + 1;
      // });
      // const workoutNow = workoutWeek.reduce((r, a) => {
      //   r[a.id] = { name: a.name, description: a.description, duration: a.duration, index: a.index } || [];
      //   return r;
      // }, {});
      //console.log({ workoutWeek });
      //console.log(workoutNow)
      // const name = workoutNow[id].name;
      // const description = workoutNow[id].description;
      // const duration = workoutNow[id].duration;
      // const index = workoutNow[id].index
      let slickWeek;
      console.log({ week, latestWeek });
      if (latestWeek > parseInt(week)) {
        slickWeek = parseInt(week);
      } else {
        slickWeek = latestWeek;
      }
      this.props.getWeekWorkOut(slickWeek);
      //console.log(week);
      //this.props.history.push(`/ListWorkOut/${id}/${description}/${name}/${duration}/${index}`);
      this.props.history.push(`Workout/${slickWeek}`);
    }
  };
  goToRecipes = () => {
    try {
      const { user, os } = this.props;
      const { expired_time, isSubscription } = user;
      const isUserExpired = moment(expired_time).isBefore(moment());
      const isSubscriptionUser = parseInt(isSubscription) > 0 ? true : false;
      const url = `${CLIENT_DOMAIN}Recipes/${isUserExpired}/${isSubscriptionUser}/${os}`;

      // console.log({ url });
      sendMessage({
        type: WEBVIEW_ACTION.GO_TO_RECIPES,
        url: url,
        title: "Tyen's Recipes",
      });
    } catch (error) {}
  };
  getUsername = (username) => {
    const usernameStr = username.split(" ");
    return usernameStr[0];
  };
  render() {
    const {
      user,
      workOutList,
      sliders,
      repliedAssessment,
      liveEvents,
      appVersion,
      os,
    } = this.props;
    let { currentWeek, checkIntro } = this.state;

    let { expired_time, workout_start_date } = user;
    // console.log(moment)
    // console.log(moment().utcOffset(workout_start_date))
    //console.log(workout_start_date)
    //console.log(new Date(workout_start_date))
    //var isoDate = (new Date(date.getTime() - (date.getTimezoneOffset() * 60000))).toISOString();
    const totalWeeks = moment(expired_time).diff(workout_start_date, "weeks");
    let start,
      end,
      dateArr = [];
    for (let i = 0; i <= totalWeeks; i++) {
      let startDate = moment(workout_start_date)
        .add(23, "hours")
        .format("YYYY-MM-DD");
      let endDate = moment(workout_start_date)
        .add(6, "days")
        .format("YYYY-MM-DD");
      dateArr.push({ startDate, endDate });
      workout_start_date = moment(endDate).add(1, "days");
    }
    //console.log(dateArr)
    if ((currentWeek || currentWeek === 0) && dateArr.length > 0) {
      start = dateArr[currentWeek].startDate;
      end = dateArr[currentWeek].endDate;
    }

    let isUpdated = null,
      showEvents = null,
      fixedVersion = 22;
    let app_url;
    if (os && user.appVersion) {
      if (os === "android") {
        //alert(JSON.stringify({ server: user.appVersion, appVersion, os }));
        //console.log(user.appVersion.android);
        isUpdated = appVersion == user.appVersion.android;
        console.log({ isUpdated });
        app_url = APP_URL.ANDROID_URL;
        showEvents = parseInt(appVersion) >= fixedVersion;
      } else {
        //alert(JSON.stringify({ server: user.appVersion, appVersion, os }));
        isUpdated = appVersion == user.appVersion.ios;
        app_url = APP_URL.IOS_URL;
        showEvents = parseInt(appVersion) >= fixedVersion;
      }
    }
    let now = Moment();
    const {
      stepsList,
      totalWorkout,
      workoutLeft,
      weeksLeft,
      daysLeft,
      leftTime,
      isExpired,
      userProfile,

      totalCardio,
      cardioLeft,
      assessmentId,
      disabled,
    } = this.state;
    //console.log("cardio", cardioLeft);
    console.log({ state: this.state });
    const {
      achievementsData,
      countedAchievement,
      dateAchievement,
    } = this.props;
    console.log({ countedAchievement, dateAchievement });
    return checkIntro ? (
      <div className="fadeIn">
        {/* <LinksYoutube/> */}
        <div className="page">
          <div className="title-header">
           <div className="icon">
           {isUpdated ? (
              <Link to="#" onClick={this.goToRecipes} className="icon">
                <img src={require("../images/ic_recipes.svg")} alt="" />
                <span className="newicon">NEW!</span>
              </Link>
            ) : null}
           </div>

            <h4>
              Hi{" "}
              <span>
                {user.username ? this.getUsername(user.username) : "THERE"}
              </span>
              !{" "}
              {process.env.REACT_APP_NODE_ENV === "development" ? `(TEST)` : ``}
            </h4>
            <Link
              to="#"
              onClick={() => this.setState({ modalShow: true })}
              className="icon"
            >
              <img src={require("../images/ic_profile.svg")} alt="" />
            </Link>
          </div>
          <div className="page-content">
            {user.workoutType ? (
              <Slider
                app_url={app_url}
                isUpdated={isUpdated}
                showEvents={showEvents}
                isExpired={isExpired}
                onClick={this.onClick}
                sliders={sliders}
                user={user}
                liveEvents={liveEvents}
              />
            ) : null}

            {repliedAssessment && repliedAssessment.id ? (
              <Link
                to={`/Message/${repliedAssessment.id}`}
                className="alert alert-warning"
                role="alert"
              >
                <img src={require("../images/envelope.svg")} alt="" /> You have
                an assessment from Tyen!
              </Link>
            ) : null}
            {isExpired || isExpired === null ? null : (
              <Fragment>
                {dateArr.length > 0 && (
                  <div className="current-w">
                    <div className="box">
                      {currentWeek === 0
                        ? "Trial Week"
                        : currentWeek && currentWeek > 0
                        ? `Week ${currentWeek}`
                        : null}
                      <p>
                        {start && end
                          ? `${moment(start).format("MMM DD")} to ${moment(
                              end
                            ).format("MMM DD")}`
                          : null}
                      </p>
                    </div>
                  </div>
                )}
              </Fragment>
            )}

            <DaysSessions
              img="icon-sessions.svg"
              name={["Sessions left for the week"]}
              days={
                (cardioLeft || cardioLeft === 0) && totalCardio
                  ? `${cardioLeft}/${totalCardio}`
                  : `0/0`
              }
              months={
                (workoutLeft || workoutLeft === 0) && totalWorkout
                  ? `${workoutLeft}/${totalWorkout}`
                  : null
              }
              txtnamemoths={
                workoutLeft > 1 || workoutLeft === 0 ? "Workouts" : "Workout"
              }
              txtnamedays={totalCardio ? "Cardio" : "Cardio"}
              altDay="Sessions"
            />

            <DaysSessions
              img="icon-days.svg"
              name={[`Days left of programme`]}
              days={daysLeft ? daysLeft : 0}
              months={weeksLeft ? weeksLeft : 0}
              txtnamemoths={weeksLeft > 1 || weeksLeft == 0 ? "Weeks" : "Week"}
              txtnamedays={daysLeft > 1 || daysLeft == 0 ? "Days" : "Day"}
              altDay="Days"
            />

            {/* <div className="schedule">
            <h3>
             <span>{Moment().format("dddd, MMM DD")}</span>
            </h3>

            <Calendar />
            {stepsList ? <StepsChart stepsList={stepsList} /> : null}
          </div> */}

            <div className="schedule">
              <h3>
                Schedule my workout <span>{now.format("dddd, MMM DD")}</span>
              </h3>
              {/* <Calendar /> */}
              <CalendarSchedule />
            </div>
            {stepsList && stepsList.length > 0 ? (
              <StepsChart stepsList={stepsList} />
            ) : null}
          </div>

          <div className="page-footer">
            <div className="menu">
              <Menu isActive={this.state.isActive} />
            </div>
          </div>
        </div>
        {/* {achievementsData &&
      countedAchievement &&
      user.expired_time &&
      leftTime &&
      isExpired &&
      user.username ? (
       
      ) : null} */}
        <ModalProfile
          //user={userProfile}
          achievementsData={achievementsData}
          countedAchievement={countedAchievement}
          dateAchievement={dateAchievement}
          username={user.username}
          isSubscription={user.isSubscription}
          profilePicture={user.profile_picture}
          isPurchased={user.transactionId !== null}
          isUpdated={isUpdated}
          app_url={app_url}
          show={this.state.modalShow}
          backdrop="static"
          onHide={this.modalClose}
          memberTimes={user.expired_time}
          leftTime={leftTime}
          isExpired={isExpired}
          os={os}
        />
      </div>
    ) : null;
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.workOutList,
    ...state.images,
    ...state.profileReducer,
    ...state.sliderReducer,
    ...state.cardioReducer,
    ...state.assessmentReducer,
    ...state.eventsReducer,
    ...state.achievementsReducer,
  };
};
export default connect(
  mapStateToProps,
  {
    getUser,
    getCurrentWorkOut,
    getWeekWorkOut,
    getVideo,
    getWorkOut,
    checkExpired,
    getHeartRateWeek,
    setUserExpired,
    setUserProfile,
    setProfileAssessment,
    clearReload,
    setWorkoutDone,
    getAllSlider,
    setProductList,
    setOS,
    getAllCardio,
    getCardioHistory,
    getCardioLeft,
    getAllMessage,
    getRepliedAssessment,
    getAllCardioHistory,
    getAllEvents,
    updateRatingStatus,
    getListAchievementDetail,
    getAllUserAchievements,
  }
)(Index);
