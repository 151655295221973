import React, { Component } from "react";
import Back from "../Button/Back";
import YoutubeWorkout from "./YoutubeWorkout";
import SliderWorkout from "../Slider/SliderWorkout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Cardio from "../Cardio";
import { getFullBodyWorkout } from "../../store/actions/GetWorkOut";
import { API } from "../../config/config";
class Workout extends Component {
  state = {
    fullBodyWorkout: null,
  };
  componentDidMount = async () => {
    try {
      const fullBodyWorkout = await this.props.getFullBodyWorkout();
      // console.log({ fullBodyWorkout });
      this.setState({ fullBodyWorkout });
    } catch (error) {
      console.log({ fullBodyWorkoutError: error });
    }
  };
  backToProgramme = () => {
    this.props.history.push("/programme");
  };

  onClick = () => {
    const { week } = this.props;
    this.props.history.push(`/Cardio/${week}`);
  };
  render() {
    const { week, message, cardioList } = this.props;
    let newCardioList = cardioList.reduce((r, a) => {
      r[a.week] = { session: a.session, id: a.id, duration: a.duration };
      return r;
    }, {});

    const session = newCardioList[week] ? newCardioList[week].session : null;
    //console.log('session', session)
    const videoId = message[week] ? message[week].video : null;
    return (
      <div className="page fadeIn">
        <div className="page-header">
          <Back onClick={this.backToProgramme} />
          <h4>{week >= 1 ? `Week ${week}` : `FREE Trial Week`}</h4>

          <Link to="/ListWorkout" className="icon" />
        </div>
        <div className="page-content page-content-workout">
          {videoId ? <YoutubeWorkout videoId={videoId} /> : null}
          <div className="width_workout_slider">
            <h3 className="title-main">Programme</h3>
            <SliderWorkout />
          </div>
          {this.state.fullBodyWorkout &&
          this.state.fullBodyWorkout.length > 0 ? (
            <div className="width_workout_slider">
              <h3 className="title-main">
                Targeted Workouts <span className="textred">NEW!</span>
                <p className="textbellow">
                  Now you can choose which specific part of your body you would
                  like to target.
                </p>
              </h3>
              <SliderWorkout
                isBodyCategory={true}
                fullBodyWorkout={this.state.fullBodyWorkout}
              />
            </div>
          ) : null}
          {session ? (
            <div className="cardiooutside">
              <h3 className="title-main">Cardio</h3>
              <div className="box">
                <p>
                  {" "}
                  {session} {session > 1 ? "cardios" : "cardio"} this week
                </p>{" "}
                <div className="button">
                  <button onClick={this.onClick} className="button">
                    Start Cardio
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.workOutList,
    ...state.cardioReducer,
  };
};
export default connect(
  mapStateToProps,
  { getFullBodyWorkout }
)(Workout);
