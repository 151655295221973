import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { sendWorkOutHistory } from "../store/actions/WorkoutHistory";
import { getCurrentWorkOut } from "../store/actions/GetWorkOut";
import { sendDiary } from "../store/actions/Diary";
import { sendMessage } from "../ultils/ultils";

import { updateScheduleWorkout } from "../store/actions/Schedule";
import {
  getExerciseReps,
  nextExercise,
  clearProgress,
} from "../store/actions/Exercise";
import { WEBVIEW_ACTION, MODAL_TEXT } from "../config/config";
import moment from "moment";
import ModalRating from "./Modal/ModalRating";
import ModalAchievementPopUp from "./Modal/ModalAchievementPopup";
import { getListAchievementDetail } from "../store/actions/Achievements";
class Congratulations extends Component {
  state = {
    showModal: false,
    stopSound: false,
    achievementsList: [],
  };
  componentDidMount = async () => {
    const { achievementsListDetail } = this.props;
    const now = moment().format("YYYY MM DD");
    sendMessage({ type: WEBVIEW_ACTION.KEEP_AWAKE });
    await this.props.updateScheduleWorkout(now);
    const { workoutHistory } = this.props.currentWorkOut;
    let { index, progress, user, isFullBodyWorkout } = this.props;
    index = null;
    const { id } = this.props.match.params;
    await this.props.clearProgress();
    await this.props.nextExercise(index);
    if (!achievementsListDetail) {
      await this.props.getListAchievementDetail();
    }

    const achievements = await this.props.sendWorkOutHistory(
      id,
      isFullBodyWorkout
    );
    this.setState({ achievementsList: achievements });
    console.log(achievements);
    // if (user.allWorkoutHistory + 1 === 3) {
    //   this.setState({ showModal: true });
    // } else {
    //   console.log(user.allWorkoutHistory + 1);
    // }
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.achievementsList !== this.state.achievementsList &&
      this.state.achievementsList.length > 0
    ) {
      const { achievementsList, showModal } = this.state;
      const { achievementsListDetail } = this.props;
      console.log({ achievementsList, achievementsListDetail });
      this.setState({ showModal: true });
    }
  };
  onRating = () => {
    this.setState({ showModal: false });
    const data = {
      type: WEBVIEW_ACTION.ON_RATING,
      //message: "Test Message"
    };
    console.log(data);
    sendMessage(data);
  };
  onClose = () => {
    this.setState({ stopSound: true });
    this.setState({ showModal: false });
  };
  render() {
    const { achievementsList, showModal, stopSound } = this.state;
    const { achievementsListDetail } = this.props;
    console.log({ achievementsList, achievementsListDetail });
    return (
      <div className="page congratulations_page fadeIn">
        <div className="bgCongra">
          <img src={require("../images/bg_c.svg")} alt="Congratulations" />
          <h3>
            Congratulations <span>Workout completed! </span>
          </h3>
        </div>

        <div className="congratulations_btn">
          <div className="button_2">
            {/* <div className="take_Photo"><input
              type="file"
              className="file"
              accept="image/*"
            />TAKE A PHOTO</div> */}

            <Link className="take_Photo" to="/progress">
              TAKE A PHOTO
            </Link>
          </div>
          <Link to="/Index">
            GO TO HOME
            <img src={require("../images/icn_arrow.png")} alt="arrow" />
          </Link>
        </div>
        <ModalAchievementPopUp
          stopSound={stopSound}
          show={showModal}
          achievementsList={achievementsList}
          achievementsListsDetail={achievementsListDetail}
          onClose={this.onClose}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    ...state.userInfo,
    ...state.exerciseReducer,
    ...state.diary,
    ...state.workOutList,
    ...state.achievementsReducer,
    ...state.isFullBodyWorkout,
  };
};
export default connect(
  mapStateToProps,
  {
    sendWorkOutHistory,
    getExerciseReps,
    nextExercise,
    clearProgress,
    updateScheduleWorkout,
    getCurrentWorkOut,
    getListAchievementDetail,
  }
)(Congratulations);
