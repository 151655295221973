import React, { Component, Fragment } from 'react'
import { MENU, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";
class LiveEvent extends Component {
  // componentDidMount() {
  //   const { event } = this.props;
  //   console.log({ event })
  // }
  onClick = (e) => {
    const url = e.target.dataset.url
    const start = e.target.dataset.start
    const end = e.target.dataset.end
    const message = e.target.dataset.message
    const data = {
      type: WEBVIEW_ACTION.SCHEDULE_WORKOUT,
      url: url,
      startDate: start,
      endDate: end,
      message: message
    }
    console.log(e.target.dataset)
    sendMessage(data)
  }
  render() {
    const { event } = this.props
    console.log(event)
    return (
      <div className="mess">
        {event.message}
        <div className="button">
          <button
            data-url={event.url}
            data-start={event.event_date_time}
            data-end={event.event_end_time}
            data-message={event.message}
            onClick={this.onClick}
            className="button-w220">
            Add to Calendar
          </button>
        </div>
      </div>
    )
  }


}


export default LiveEvent