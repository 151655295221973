import React, { Component } from "react";

class Completed extends Component {
  componentDidUpdate() {
    const meters = document.querySelectorAll("svg[data-value] .meter");
    meters.forEach(path => {
      let length = path.getTotalLength();
      let value = parseInt(path.parentNode.getAttribute("data-value"));
      let to = length * ((100 - value) / 100);
      path.getBoundingClientRect();
      path.style.strokeDashoffset = Math.max(0, to);
    });
  }
  render() {
    return (
      <div className="progress_bar_circle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="180"
          width="180"
          viewBox="0 0 200 200"
          data-value={this.props.value}
        >
          <path className="bg" d="M41 149.5a77 77 0 1 1 117.93 0" fill="none" />
          <path
            className="meter"
            d="M41 149.5a77 77 0 1 1 117.93 0"
            fill="none"
            strokeDasharray="350"
            strokeDashoffset="350"
          />
        </svg>

        <p>
          {Math.round(this.props.value)}%<span>Completed</span>
        </p>
      </div>
    );
  }
}

export default Completed;
