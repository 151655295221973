import React, { Component, Fragment } from "react";
import Close from "./Button/Close";
import { Link, withRouter } from "react-router-dom";
import Bxupgrade from "./Button/Bxupgrade";
import { connect } from "react-redux";
import Slider from "react-slick";
import { getUser } from "../store/actions/UserInfo";
import {
  setProfileAssessment /* reloadAssessment */,
} from "../store/actions/Profile";
import { Tabs, Tab, Sonnet } from "react-bootstrap/Tabs";
import {
  MENU,
  WEBVIEW_ACTION,
  UPGRADE_OPTIONS,
  MODAL_TEXT,
} from "../config/config";
import { sendMessage } from "../ultils/ultils";
import ModalRating from "./Modal/ModalRating";
export class Upgrade extends Component {
  state = {
    userExpired: null,
    message: null,
    productList: null,
    showModal: false,
  };
  onUpgrade = async (e) => {
    const id = e.target.name;
    console.log(id);
    const { userExpired } = this.state;
    const { user } = this.props;
    let assessment =
      user.userAssessment && user.userAssessment > 0 ? user.userAssessment : 0;
    if (!user.username) {
      this.setState({ showModal: true });
    } else {
      //this.setState({ showModal: true });
      sendMessage({
        type: WEBVIEW_ACTION.ON_UPGRADE,
        id: id,
        expiredTime: userExpired,
        assessment: assessment,
      });
    }

    //this.props.reloadAssessment()
  };
  onClose = () => {
    console.log("onClose");
    this.setState({ showModal: false });
  };
  userRegister = () => {
    console.log("userRegister");
    const data = {
      type: WEBVIEW_ACTION.FORCE_REGISTER,
      goUpgrade: true,
    };
    console.log(data);
    this.setState({ showModal: false });
    sendMessage(data);
  };
  componentDidMount = async () => {
    const { userExpired, user } = this.props;
    // let data = await this.props.getUser();
    if (!user.username) {
      await this.props.getUser();
    }
    this.setState({ userExpired: userExpired });
    /* var now = new Date();
    var isoDate = new Date(now.getTime() - now.getTimezoneOffset() * (60000 - 10)).toISOString();
    console.log(now)
    console.log(isoDate) */
    window.WebViewBridge = {
      get_success: this._onSuccess,
      get_product_list: this._onMessage,
    };
    const event = new Event("WebViewBridge");
    window.dispatchEvent(event);
    sendMessage({ type: WEBVIEW_ACTION.GET_PRODUCT_LIST });
  };
  _onMessage = (data) => {
    if (data.type === "get_product_list") {
      this.setState({ productList: data.productList });
    }
  };

  _onSuccess = () => {
    this.props.history.push("/ThanksSubscription");
  };
  componentDidUpdate = (prevProps, prevState) => {
    // window.WebViewBridge = {
    //   get_success: this._onMessage,
    //   get_product_list: this._onMessage
    // };
    // const event = new Event('WebViewBridge');
    // window.dispatchEvent(event);
  };
  onClick = () => {
    sendMessage({ type: WEBVIEW_ACTION.GO_TO_INDEX });
    //this.props.history.push("/Index");
  };
  testClick = (e) => {
    console.log(1);
  };
  formatNumber = (num, fixed = 2) => {
    var decimalPart;

    var array = Math.floor(num)
      .toString()
      .split("");
    var index = -3;
    while (array.length + index > 0) {
      array.splice(index, 0, ",");
      index -= 4;
    }

    if (fixed > 0) {
      decimalPart = num.toFixed(fixed).split(".")[1];
      return array.join("") + "." + decimalPart;
    }
    return array.join("");
  };
  render() {
    const settings3 = {
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      infinite: false,
    };
    let oneYearPrice, twelveWeeksPrice, fourWeeksPrice, currencyPrice;
    const { userInfo, user } = this.props;
    const { productList } = this.state;
    let priceList = productList
      ? (priceList = productList.reduce((r, a) => {
          r[a.productId] = {
            price: a.price,
            currency: a.currency,
            localizedPrice: a.localizedPrice,
          };
          return r;
        }, {}))
      : null;
    if (priceList) {
      oneYearPrice = priceList[UPGRADE_OPTIONS.ONE_YEAR].price;
      twelveWeeksPrice = priceList[UPGRADE_OPTIONS.TWELVE_WEEKS].price;
      fourWeeksPrice = priceList[UPGRADE_OPTIONS.FOUR_WEEKS].price;
      currencyPrice = priceList[UPGRADE_OPTIONS.FOUR_WEEKS].currency;
    }
    return (
      <div>
        <a onClick={this.onClick} className="icon icon_close closeUpgrade">
          <img src={require("../images/icn_close_p@2.png")} alt="close" />
        </a>
        <div className="colm_costume colm_upgrade">
          <Slider {...settings3} className="sliderUpgrade">
            <div className="contentSub padding">
              <h1>
                HEY{" "}
                <span>
                  {user.username
                    ? user.username
                    : userInfo.name.value
                    ? userInfo.name.value
                    : "THERE"}
                </span>
                ,{" "}
              </h1>
              <h4>Welcome to the start of your fitness journey with me! </h4>
              <p>
                Your TRIAL WEEK PROGRAMME is FREE, and unlocking the full
                programme requires a paid subscription.
              </p>
              <p>
                I’ve designed 3 different <br />
                plans for you to choose from.
              </p>
              <p className="large">This is not just an app.</p>
              <p>
                You’ll be able to submit personal fitness questions to me, and
                we’ll even do live chats and workout sessions together!
              </p>
              <p className="large">Come join me :)</p>
              Love, Tyen
            </div>
            <div className="contentSub">
              <div>
                <h3>
                  1 Year Programme <br />
                  (52 WEEKS)
                </h3>
                <h5>
                  {priceList ? (
                    <Fragment>
                      {`${this.formatNumber(parseFloat(oneYearPrice / 12))}`}{" "}
                      {currencyPrice}/month{" "}
                      <span>
                        {" "}
                        TOTAL{" "}
                        {`${this.formatNumber(
                          parseFloat(oneYearPrice)
                        )} ${currencyPrice}`}{" "}
                      </span>
                    </Fragment>
                  ) : null}
                </h5>
                <hr />
                <p className="uppercase">Most recommended! </p>
                Join me and make fitness your lifestyle too.
                <ul>
                  <li>Customised workout programme</li>
                  <li>Livestream chats {"&"} workout sessions with Tyen</li>
                  <li>4 personal fitness assessments from Tyen</li>
                  <li> Invitations to exclusive meetups</li>
                  <li>
                    FREE set of No Sweat Resistance Bands worth SGD19.90 (For
                    subscribers in Singapore only as item has to be
                    self-collected. Delivery is available too, but additional
                    postage fees will apply.)
                  </li>
                </ul>
                <Link
                  name={UPGRADE_OPTIONS.ONE_YEAR}
                  to="#"
                  onClick={this.onUpgrade}
                  className="button"
                >
                  JOIN NOW
                </Link>
              </div>
            </div>

            <div className="contentSub">
              <div>
                <h3>
                  3 Month Programme <br />
                  (12 WEEKS)
                </h3>

                <h5>
                  {priceList ? (
                    <Fragment>
                      {`${this.formatNumber(parseFloat(twelveWeeksPrice / 3))}`}{" "}
                      {currencyPrice}/month{" "}
                      <span>
                        {" "}
                        TOTAL{" "}
                        {`${this.formatNumber(
                          parseFloat(twelveWeeksPrice)
                        )} ${currencyPrice}`}{" "}
                      </span>
                    </Fragment>
                  ) : null}
                </h5>

                <hr />

                <p>
                  Need to get in shape fast?
                  <br />
                  Try my transformation challenge <br />
                  like no other!
                </p>
                <ul>
                  <li>Customised workout programme</li>
                  <li>Livestream chats & workout sessions with Tyen</li>
                  <li style={{ marginBottom: "87px" }}>
                    2 personal fitness assessments from Tyen
                  </li>
                </ul>

                <Link
                  to="#"
                  name={UPGRADE_OPTIONS.TWELVE_WEEKS}
                  onClick={this.onUpgrade}
                  className="button"
                >
                  JOIN NOW
                </Link>
              </div>
            </div>
            <div className="contentSub">
              <div>
                <h3>
                  1 Month Programme <br />
                  (4 WEEKS)
                  {/* <i>(5 WEEKS)</i> */}
                </h3>

                <h5>
                  {priceList ? (
                    <Fragment>
                      {/* <i>
                        {`${this.formatNumber(parseFloat(fourWeeksPrice / 1))}`}{" "}
                        {currencyPrice}{" "}
                      </i> */}
                      {`${this.formatNumber(parseFloat(fourWeeksPrice / 1))}`}{" "}
                      {currencyPrice}/month{" "}
                      <span>
                        {" "}
                        TOTAL{" "}
                        {`${this.formatNumber(
                          parseFloat(fourWeeksPrice)
                        )} ${currencyPrice}`}{" "}
                      </span>
                    </Fragment>
                  ) : null}
                </h5>
                <hr />
                <p>
                  Want to try personal training but <br /> not sure if you’re
                  ready to commit?
                  <br /> Try this low-risk option!{" "}
                </p>

                <ul>
                  <li>Customised workout programme</li>
                  <li>Livestream chats & workout sessions with Tyen</li>
                  <li style={{ marginBottom: "87px" }}>
                    1 personal fitness assessment from Tyen
                  </li>
                </ul>

                <Link
                  to="#"
                  name={UPGRADE_OPTIONS.FOUR_WEEKS}
                  onClick={this.onUpgrade}
                  className="button"
                >
                  JOIN NOW
                </Link>
                {priceList ? (
                  <ModalRating
                    backdrop="static"
                    text={MODAL_TEXT.REGISTER_TRIAL_USER}
                    type={this.state.type}
                    onHandler={this.userRegister}
                    show={this.state.showModal}
                    //onCancel={this.onClose}
                  />
                ) : null}
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.userInfo,
    ...state.workOutList,
    ...state.images,
    ...state.profileReducer,
    ...state.sliderReducer,
    ...state.cardioReducer,
    ...state.assessmentReducer,
    ...state.eventsReducer,
    ...state.achievementsReducer,
    ...state.upgradeReducer,
  };
};
export default connect(
  mapStateToProps,
  { getUser /* reloadAssessment */ }
)(Upgrade);
