import React, { Component, Fragment } from "react";
import Back from "../containers/Button/Back";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import { updateCardioHistory } from "../store/actions/Cardio";
import { updateScheduleCardio } from "../store/actions/Schedule";
import { sendDiary } from "../store/actions/Diary";
import { DIARY_TYPES, ERRORS } from "../config/config";
import { getTotalWeeks, getWeeksArr } from "../ultils/ultils";
import moment from "moment";
import CalendarSchedule from "./Calendar/CalendarSchedule";
import ModalAchievementPopUp from "./Modal/ModalAchievementPopup";
import { getListAchievementDetail } from "../store/actions/Achievements";
class Cardio extends Component {
  state = {
    cardio: "",
    cardioLeft: null,
    startTime: null,
    isOther: false,
    date: null,
    startDate: null,
    endDate: null,
    dateErr: null,
    onSubmit: false,
    showAchievement: false,
    stopSound: false,
    achievementsList: [],
  };
  componentDidMount = async () => {
    const startTime = moment().format("YYYY-MM-DD HH:mm:ss");
    this.setState({ startTime });
    const { user } = this.props;
    const { week } = this.props.match.params;
    let startDate, endDate;
    let { expired_time, workout_start_date } = user;
    const totalWeeks = getTotalWeeks(expired_time, workout_start_date);
    const weeksArr = getWeeksArr(totalWeeks, workout_start_date);
    if (week) {
      // console.log(weeksArr[week])
      startDate = weeksArr[week].startDate;
      endDate = weeksArr[week].endDate;
    }
    this.setState({ startDate, endDate });
  };
  onBack = () => {
    this.props.history.goBack();
  };
  onClick = (value) => {
    if (value === "Other") {
      this.setState({ isOther: true });
    } else {
      this.setState({ cardio: value, isOther: false });
    }
  };
  onChange = (e) => {
    this.setState({ cardio: e.target.value });
  };
  onCloseAchievement = () => {
    this.setState({ stopSound: true });
    this.setState({ showAchievement: false });
    setTimeout(() => {
      this.props.history.push("/index");
    }, 300);
  };
  onSubmit = async () => {
    const now = moment();
    const { cardioList, cardioSession, user } = this.props;
    const { cardioLeft, totalCardio } = cardioSession;
    const { week } = this.props.match.params;
    const { endDate } = this.state;
    //let currentWeek = this.props.currentWorkOut.week
    const { cardio, startTime } = this.state;
    let newCardioList = cardioList.reduce((r, a) => {
      r[a.week] = { session: a.session, id: a.id, duration: a.duration };
      return r;
    }, {});

    //console.log({ week, currentWeek, totalWeeks })
    const duration = newCardioList[week].duration;
    const cardioId = newCardioList[week].id;
    //const session = newCardioList[week].session;
    const { dateErr } = this.state;
    let diary = {
      feedback: cardio,
      duration: duration,
      startTime: startTime
        ? startTime
        : moment().isAfter(moment(endDate))
        ? moment(endDate)
            .set({
              hour: now.hour(),
              minute: now.minute(),
              second: now.second(),
            })
            .format("YYYY-MM-DD HH:mm:ss")
        : moment().format("YYYY-MM-DD HH:mm:ss"),
      type: DIARY_TYPES.CARDIO,
    };
    if (cardio.length > 0 && !dateErr) {
      this.setState({ onSubmit: true });
      const achievements = await this.props.updateCardioHistory(cardioId);
      const now = moment().format("YYYY MM DD");
      await this.props.sendDiary(diary);
      await this.props.updateScheduleCardio(now);
      console.log({ achievements });
      if (achievements.length > 0) {
        this.setState({
          showAchievement: true,
          achievementsList: achievements,
        });
      } else {
        this.props.history.push("/index");
      }

      //console.log(diary)
    }
  };
  onChangeDate = (event) => {
    this.setState({ dateErr: null, startTime: null });
    let { startDate, endDate } = this.state;
    const now = moment();
    let dateCompare = moment(endDate).isBefore(moment())
      ? moment(endDate)
      : moment().format("YYYY-MM-DD 00:00:00");
    let dateSelected = moment(event.target.value).set({
      hour: now.hour(),
      minute: now.minute(),
      second: now.second(),
    });
    let dateIsAfter = dateSelected.isAfter(moment(startDate));
    let dateIsBefore = dateSelected.isBefore(
      moment(dateCompare).add(23, "hours")
    );
    //console.log({ dateSelected, dateIsAfter, dateIsBefore })
    if (dateIsAfter && dateIsBefore) {
      this.setState({
        startTime: dateSelected.format("YYYY-MM-DD HH:mm:ss"),
        dateErr: null,
      });
    } else {
      this.setState({
        dateErr: ERRORS.dateErr,
        startTime: moment(event.target.value),
      });
    }
  };
  render() {
    const {
      cardio,
      isOther,
      startTime,
      startDate,
      endDate,
      dateErr,
      onSubmit,
      showAchievement,
      achievementsList,
      stopSound,
    } = this.state;
    const { cardioSession, user, achievementsListDetail } = this.props;
    //console.log({ achievementsListDetail, achievementsList });
    let duration = null;
    if (cardioSession) {
      duration = cardioSession.duration;
    }
    //console.log(cardioSession);
    return (
      <Fragment>
        {/* <div>
          <h4>TEST</h4>
        </div> */}

        <div className="page fadeIn">
          <div className="page-header">
            <Back onClick={this.onBack} />
            <h4>Cardio</h4>
            <Link to="" className="icon" />
          </div>
          <div className="page-content">
            <div class="rate2 cardio">
              <p className="mb-0">Select activity:</p>
              <ul>
                <li>
                  <input type="radio" id="w" name="selector" />
                  <label onClick={() => this.onClick("Walking")} htmlFor="w">
                    Walking
                  </label>

                  <div className="check" />
                </li>
                <li>
                  <input type="radio" id="r" name="selector" />
                  <label onClick={() => this.onClick("Running")} htmlFor="r">
                    Running
                  </label>

                  <div className="check" />
                </li>
                <li>
                  <input type="radio" id="c" name="selector" />
                  <label onClick={() => this.onClick("Cycling")} htmlFor="c">
                    Cycling
                  </label>

                  <div className="check" />
                </li>
                <li>
                  <input type="radio" id="s" name="selector" />
                  <label onClick={() => this.onClick("Swimming")} htmlFor="s">
                    Swimming
                  </label>

                  <div className="check" />
                </li>
                <li>
                  <input type="radio" id="o-option" name="selector" />
                  <label
                    onClick={() => this.onClick("Other")}
                    htmlFor="o-option"
                  >
                    Other
                  </label>
                  <div className="check" />
                  {isOther ? (
                    <div className="enter-active">
                      <span>Enter activity:</span>{" "}
                      <Textarea onChange={(e) => this.onChange(e)} />
                    </div>
                  ) : null}
                </li>
              </ul>
              <p className="duration">
                <span>Duration</span>
                {duration ? duration : "30"} Min
              </p>
              <p className="date_cadio mt-4">
                Date:{" "}
                <input
                  value={
                    startTime
                      ? moment(startTime).format("YYYY-MM-DD")
                      : moment().isAfter(moment(endDate))
                      ? moment(endDate).format("YYYY-MM-DD")
                      : moment().format("YYYY-MM-DD")
                  }
                  onChange={this.onChangeDate}
                  type="date"
                />
              </p>
              {dateErr ? <p className="error">{dateErr}</p> : null}
            </div>
          </div>
          <div className="letgo-button">
            <div className="button">
              <button
                to="#"
                onClick={this.onSubmit}
                disabled={dateErr || onSubmit}
                className={dateErr ? "button disable" : "button"}
              >
                OK
              </button>
            </div>
          </div>
          <ModalAchievementPopUp
            stopSound={stopSound}
            show={showAchievement}
            achievementsList={achievementsList}
            achievementsListsDetail={achievementsListDetail}
            onClose={this.onCloseAchievement}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.cardioReducer,
    ...state.userInfo,
    ...state.workOutList,
    ...state.achievementsReducer,
  };
};
export default connect(mapStateToProps, {
  updateScheduleCardio,
  updateCardioHistory,
  sendDiary,
  getListAchievementDetail,
})(Cardio);
