import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { PRIVACY, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";

class ModalContactUs extends Component {
  sendAddress = e => {
    let url,
      id = e.currentTarget.dataset.id;
    switch (id) {
      case PRIVACY.SEND_EMAIL:
        url = PRIVACY.EMAIL_URL;
        break;

      default:
        break;
    }
    //console.log(url)
    sendMessage({ type: WEBVIEW_ACTION.SEND_URL, url: url })
  };
  render() {
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg">
          <div className="page pageNomal">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.props.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Contact Us</h4>
              <Link to="#" className="icon" />
            </div>

            <div className="privacy link-emailto">
              <p>
              Click on the email address below <br /> to contact us:{" "} 
                <Link
                  to="#"
                  data-id={PRIVACY.SEND_EMAIL}
                  onClick={this.sendAddress}
                >
                 nosweatfitnessapp@gmail.com
                </Link>
              </p>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalContactUs;
