import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { PRIVACY, WEBVIEW_ACTION } from "../../config/config";
import { sendMessage } from "../../ultils/ultils";

class ModalPrivacy extends Component {
  sendAddress = e => {
    let url,
      id = e.currentTarget.dataset.id;
    switch (id) {
      case PRIVACY.SEND_EMAIL:
        url = PRIVACY.EMAIL_URL;
        break;

      default:
        break;
    }
    //console.log(url)
    sendMessage({ type: WEBVIEW_ACTION.SEND_URL, url: url })
  };
  render() {
    return (
      <div>
        <Modal className="modalProfileChild" {...this.props} size="lg">
          <div className="page pageFeedBack">
            <div className="page-header">
              <Link to="#" className="icon" onClick={this.props.onHide}>
                {/* <img src={require("../../images/back.svg")} alt="" /> */}
                Back
              </Link>
              <h4>Privacy Policy</h4>
              <Link to="#" className="icon" />
            </div>

            <div className="privacy">

              <p>We use technologies like cookies (small files stored on your browser), web beacons, or unique device identifiers to identify your computer or device so we can deliver a better experience. Our systems also log information like your browser, operating system and IP address.</p>
              <p>We also may collect personally identifiable information that you provide to us, such as your name, address, phone number or email address. With your permission, we may also access other personal information on your device, such as your phone book, calendar or messages, in order to provide services to you. If authorized by you, we may also access profile and other information from services like Facebook. Our systems may associate this personal information with your activities in the course of providing service to you (such as pages you view or things you click on or search for). We do not use or collect your precise geographic location.
            </p>
              <p>
                You can sign into your account to see any personally identifiable information we have stored, such as your name, email, address or phone number. You can also contact us by email to request to see this information. We may keep data indefinitely. We do not share personally identifiable information (such as name, address, email or phone) with other companies.
We do not allow advertising companies to collect data through our service for ad targeting.
If you have any questions or concerns about our privacy policy, please contact us at <Link
                  to="#"
                  data-id={PRIVACY.SEND_EMAIL}
                  onClick={this.sendAddress}
                >
                 nosweatfitnessapp@gmail.com
                </Link>. Our privacy policy may change from time to time. Any changes will be communicated to you by way of an email or a notice on our website.
                </p>

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModalPrivacy;
