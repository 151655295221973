import * as actionTypes from "./actionTypes";
import { headers } from "../../ultils/helpers";
import { API } from "../../config/config";

export const getListAchievementDetail = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_LIST_ACHIEVEMENTS_DETAIL_API, {
      method: "GET",
      headers: headers
    })
      .then(res => res.json())
      .then(data => {
        let sortedData = data.reduce((acc, cur) => {
          acc[cur.code] = {
            title: cur.name,
            photo: cur.photo,
            description: cur.message
          };
          return acc;
        }, {});

        dispatch({
          type: actionTypes.GET_LIST_ACHIEVEMENTS_DETAIL,
          data: { sortedData, data }
        });
        return resolve(sortedData);
      })
      .catch(err => reject(err));
  });
};

export const getAllUserAchievements = () => dispatch => {
  return new Promise((resolve, reject) => {
    fetch(API.GET_ALL_USER_ACHIEVEMENTS_API, {
      method: "GET",
      headers
    })
      .then(res => res.json())
      .then(data => {
        let countedAchievement = data.reduce((acc, cur) => {
          let obj = [];
          acc[cur.code] = acc[cur.code] + 1 || 1;
          return acc;
        }, {});
        let dateAchievement = data.reduce((acc, cur) => {
          acc[cur.code] = cur.date;
          return acc;
        }, {});
        //console.log({ countedAchievement, dateAchievement });
        dispatch({
          type: actionTypes.GET_ALL_USER_ACHIEVEMENTS,
          data: { countedAchievement, dateAchievement }
        });
        return resolve(data);
      })
      .catch(err => reject(err));
  });
};
