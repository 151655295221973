import * as actionTypes from "./actionTypes";
import { API } from "../../config/config";
import { headers } from "../../ultils/helpers";

export const sendWorkOutHistory = (workoutId, isTargetedWorkout) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    fetch(API.CREATE_WORKOUT_HISTORY_API, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ workoutId, isTargetedWorkout }),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};
