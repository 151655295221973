import React, { Component } from "react";
import YouTube from "react-youtube";
class YoutubeWorkout extends Component {
  playVideo = () => {
    this.videoObj.playVideo();
  };
  onReady = e => {
    //alert('ready');
    this.videoObj = e.target;
  };
  onClickVideo = () => {
    //this.setState({ hideBanner: true });
    this.videoObj.playVideo();
    // console.log(this.videoObj)
    // this.videoObj.playVideo();
  };
  pauseVideo = async () => {
    await this.videoObj.pauseVideo();
    //this.setState({ hideBanner: false });
  };
  render() {
    const { videoId } = this.props;
    //console.log("videoID", videoId);
    const opts = {
      // height: '390',
      width: "100%",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        playsinline: 1,
        autoplay: 0,
        controls: 1,
        fs: 0,
        rel: 0,
        modestbranding: 0,
        showinfo: 0,
        autohide: 1,
        enablejsapi: 1,
        html5: 1
      }
    };
    return (
      <div className="from-youtube">
        <h3 className="title-main">Message from Tyen </h3>
        <div className="embed-responsive embed-responsive-16by9">
          <YouTube
            opts={opts}
            videoId={videoId}
            onReady={this.onReady}
            onPause={this.pauseVideo}
          />
        </div>
      </div>
    );
  }
}

export default YoutubeWorkout;
