import React, { Component } from "react";

class ListWorkoutContent extends Component {
  render() {
    var { img, workoutName, reps, sets, id, workoutItem } = this.props;
    return (
      <li onClick={() => this.props.onClick(workoutItem)}>
        <div className="img">
          <img src={img} alt="Test" />
        </div>
        <div className="content">
          <p>
            {workoutName}
            <span>{reps}</span>
          </p>
          <p className="time">
            {sets} {sets > 1 ? "Sets" : "Set"}
          </p>
        </div>
      </li>
    );
  }
}

export default ListWorkoutContent;
