import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getWorkOutId,
  changeFullBodyWorkoutStatus,
} from "../../store/actions/GetWorkOut";

const WORKOUT_CATEGORIES_IMG = {
  "1": require("../../images/workout_categories/Total-Body.jpeg"),
  "2": require("../../images/workout_categories/Legs-&-Abs.jpeg"),
  "3": require("../../images/workout_categories/Upper-Body-&-Abs.jpeg"),
  "4": require("../../images/workout_categories/Abs-&-Cardio.jpeg"),
  "5": require("../../images/workout_categories/Cardio.jpeg"),
  "7": require("../../images/workout_categories/Legs.jpeg"),
  "8": require("../../images/workout_categories/Abs.jpeg"),
  "9": require("../../images/workout_categories/Upper-Body.jpeg"),
};

class SliderWorkout extends Component {
  onClick = async (workOutId) => {
    await this.props.getWorkOutId(workOutId);
  };
  render() {
    const settings = {
      className: "slider variable-width",
      variableWidth: true,
      infinite: false,
      slidesToShow: 1,
      speed: 500,
    };
    const { week, workOutList, fullBodyWorkout, isBodyCategory } = this.props;
    console.log({ fullBodyWorkout });
    // console.log('default week', week)
    console.log({ week });
    console.log(workOutList[week]);
    return (
      <div>
        <Slider {...settings} className="workout_slider">
          {workOutList && !isBodyCategory
            ? Object.keys(workOutList).map((key) => {
                return workOutList[key].map((item, index) =>
                  item.week == week ? (
                    <div>
                      <Link
                        // onClick={this.onClick.bind(this, item.id)}
                        // to="/ListWorkOut/"
                        to={`/ListWorkout/${item.id}/${item.description}/${
                          item.name
                        }/${item.duration}/${index + 1}`}
                        className="item"
                      >
                        <h4>
                          <img src={item.thumbnail} alt="" />
                          <span className={item.historyId ? "tick_done" : ""} />
                        </h4>
                        <p>
                          Workout {index + 1} <br />
                          {item.description}
                          <span>{item.duration} min</span>
                        </p>
                      </Link>
                    </div>
                  ) : null
                );
              })
            : null}
          {fullBodyWorkout && isBodyCategory
            ? fullBodyWorkout.map((item, index) => (
                <div>
                  <Link
                    to={`/ListWorkout/${item.id}/${null}/${
                      item.name
                    }/${null}/${index + 1}/${1}`}
                    className="item"
                  >
                    <h4>
                      <img src={WORKOUT_CATEGORIES_IMG[`${item.id}`]} alt="" />
                    </h4>
                    <p>{item.name}</p>
                  </Link>
                </div>
              ))
            : null}
          {/* <div>
            <Link to="/ListWorkout" className="item">
              <img src={require("../images/w1.jpg")} alt="" />
              <p>
                Workout A - Total body<span>45 min</span>
              </p>
            </Link>
          </div>
          <div>
            <Link to="/ListWorkout" className="item">
              <img src={require("../images/w1.jpg")} alt="" />
              <p>
                Workout A - Total body<span>45 min</span>
              </p>
            </Link>
          </div> */}
        </Slider>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state.workOutList,
  };
};
export default connect(
  mapStateToProps,
  { getWorkOutId, changeFullBodyWorkoutStatus }
)(SliderWorkout);
