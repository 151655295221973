import moment from 'moment';
const now = moment().utc();
export const groupKey = (data) => {
    return data.reduce(function (r, a) {
        r[a.key] = r[a.key] || [];
        r[a.key].push(a);
        return r;
    }, {});

}

export const sendMessage = (data) => {
    setTimeout(() => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
    }, 1);
}

export const getCurrentWeek = (userRegDate) => {
    return now.diff(userRegDate, 'weeks');
}

export const isNewWeek = (userRegDate) => {
    return now.diff(userRegDate, 'days')
    //return now.diff(userRegDate, 'days') % 7 === 0;
}
export const isUserPurchase = (total_days_purchase) => {
    return total_days_purchase !== null
}

export const getWeeksArr = (totalWeeks, workout_start_date) => {
    let dateArr = [];
    for (let i = 0; i <= totalWeeks; i++) {
        let startDate = moment(workout_start_date).add(23, 'hours').format("YYYY-MM-DD")
        let endDate = moment(workout_start_date).add(6, "days").format("YYYY-MM-DD")
        dateArr.push({ startDate, endDate })
        workout_start_date = moment(endDate).add(1, "days")
    }
    //console.log(dateArr)
    return dateArr;
}

export const getTotalWeeks = (expired_time, workout_start_date) => {
    return moment(expired_time).diff(workout_start_date, 'weeks')
}
